import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Landingpage from "../components/templates/landingpage"
import SEO from "../components/atoms/seo"
import Logotype from '../components/atoms/logotype'
import IconEgrAward from "../components/atoms/IconEgrAward"
import Title from "../components/atoms/title"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteThemeColor {
      site {
        siteMetadata {
          title
          primary_color,
          secondary_color
        }
      }
    }
  `)

  return (
    <Landingpage title='Home' primaryColor={data.site.siteMetadata.primaryColor}>
        <SEO 
          title="Customer experience marketing agency"
          description="Welcome to A GAME ABOVE. We're an experience marketing agency that create, sell & operate customer sales promotions for your brand, product or service."
          keywords="marketing agency, experience marketing agency, igaming marketing agency, sales promotions agency"
        />

        <a href="https://agameabove.com" target="_blank" rel="noreferrer">
          <Logotype size='large' />
        </a>

        <Title level='hero' page='firstpage' color='#fff'>
          ACQUIRED BY <br />
          A GAME ABOVE LLC
        </Title>

        <IconEgrAward />
    </Landingpage>
  )
}

export default IndexPage
