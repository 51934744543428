import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const IconEgrAward = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "EGR-Award.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(
    <div style={{ width: '400px', maxWidth: '400px', margin: '0 auto' }}>
        <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="A GAME ABOVE - EGR MARKETING & INNOVATION AWARD" />
    </div>
  )
}

export default IconEgrAward
