import React from "react"
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components';

const StyledContainer = styled.div`
  width: ${props => props.size === 'large' ? '200px' : '80px'};
  max-width: ${props => props.size === 'large' ? '200px' : '80px'};
  display: inline-block;
  margin: 2rem auto;
  z-index: 99;
  text-align: left;
  
  @media only screen and (min-width: 600px) {
    position: ${props => props.position || 'inherit'};
  }

  @media only screen and (max-width: 600px) {
    max-width: 80px;
  }
`;

const Logotype = ({ size, position }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logotype.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return(
    <StyledContainer size={size} position={position} >
      <Img fluid={data.placeholderImage.childImageSharp.fluid} alt="A GAME ABOVE Logotype" />
    </StyledContainer>
  )
}

export default Logotype
